<template>
  <svg
    class="map country papua new guinea"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 86.3 52.5"
  >
    <g class="hover">
      <path
        d="M85.3,27.8l-1.6,.7-2.3-2.5-2.2-4.1-.8-4.9,.8-.6,.5,1.9,1.6,1.5,2.4,4,2.5,2.2-.9,1.8Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.2px;
        "
      />
      <path
        d="M63.9,19.2l-3,.5-1,1.8-3.2,1.6-3,1.5h-3.1l-4.5-1.9-3.1-1.8,.6-2,5,1,3.2-.5,1-3.1,.9-.2,.3,3.4,3.2-.4,1.8-2.2,3.3-2.3-.4-3.8,3.4-.1,1.1,1-.4,3.6-2.1,3.9Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.2px;
        "
      />
      <path
        :class="`hover papua new guinea ${isActive('Papua New Guinea')}`"
        data-loc="3,12"
        @click="quickViewShop('papua new guinea')"
        d="M.1,42.6L1.7,21.6,2.7,.7,12.3,5.1l10.3,3.7,3.7,3.3,3,3.2,.6,3.8,9.2,4,1.1,3.4-5.2,.7,.9,4.3,4.7,4.2,3,6.8,3.3-.2-.6,2.8,4.3,1.1-1.8,1.2,5.7,2.7-.9,1.9-3.8,.4-1.2-1.6-4.7-.8-5.6-.9-4-4.1-2.8-3.6-2.5-5.6-7.1-2.8-4.9,1.8-3.7,2.2,.3,4.7-4.6,2.2-3.1-1.1-5.8-.2Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.2px;
        "
      />
      <path
        d="M70.8,12.9l-1.8,1.7-.9-3.8-1.1-2.4-2.5-2.1-3.1-2.8-4-1.9,1.6-1.5,3,1.8,1.9,1.4,2.3,1.5,2.1,2.7,2.1,2.1,.4,3.3Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.2px;
        "
      />
    </g>
    <g
      class="pin"
      :transform="`translate(${targetLoc[0]} ${targetLoc[1]}) scale(0.4)`"
    >
      <path
        fill="#000"
        d="M17.54,3.52A9.9,9.9,0,0,0,12,.21,9.91,9.91,0,0,0,.22,7.65,9.48,9.48,0,0,0,0,10.18c-.16,5.18,3.92,10,7.7,15,.93,1.15,2.23,2.42,2.29,2.57l2.08-2.46C16.82,19.08,23.74,10.05,17.54,3.52Zm-2.86,7.79A4.88,4.88,0,0,1,9,15.16l-.12,0a5,5,0,0,1-3.75-5.9,4.87,4.87,0,0,1,5.78-3.82A5,5,0,0,1,14.68,11.31Z"
      ></path>
      <path
        fill="#fff"
        d="M11.55,2.93A7.37,7.37,0,0,0,2.82,8.77a7.6,7.6,0,0,0,5.67,9l.18,0a7.37,7.37,0,0,0,8.55-5.87A7.59,7.59,0,0,0,11.55,2.93Zm3.13,8.38A4.88,4.88,0,0,1,9,15.16l-.12,0a5,5,0,0,1-3.75-5.9,4.87,4.87,0,0,1,5.78-3.82A5,5,0,0,1,14.68,11.31Z"
      ></path>
      <text x="25" y="20" class="small">{{ country }}</text>
    </g>
    <text
      x="5"
      y="10"
      :class="`small ${isActive('Papua New Guinea')}`"
      style="font-size: 8px"
    >
      Papua New Guinea
    </text>
  </svg>
</template>
<script>
export default {
  name: "India",
  data() {
    return {
      targetLoc: [0, 0],
    };
  },
  props: {
    country: String,
  },
  mounted() {
    const target = document.querySelector(".map .active");
    this.targetLoc = [
      target.dataset.loc.split(",")[0],
      target.dataset.loc.split(",")[1],
    ];
    this.log("Map:#901", "Country", this.country, target);
  },
  methods: {
    isActive(query) {
      return query === this.country ? "active" : "";
    },
    quickViewShop(selection) {
      if (selection !== "shop") {
        this.$store.commit("saveFilterInStore", [selection]);
        this.$store.commit("saveActiveFilters", [1, 0, 0, 0]);
      }
      window.location = "#/user/shop";
    },
  },
};
</script>
